import { useEffect } from "react";
import { gtm } from "src/libs/gtm";

export const useGtmVimeo = (iframeId: string): void => {
  useEffect(() => {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
    if (!iframe) {
      console.warn(`No iframe found with ID: ${iframeId}`);
      return;
    }

    const handleVimeoMessage = (event: MessageEvent) => {
      // Validate the origin of the message
      if (!event.origin.includes("vimeo.com")) return;

      let data;
      try {
        data = JSON.parse(event.data); // Parse the message data
      } catch {
        return; // Exit if data is not valid JSON
      }

      if (data.event === "ready") {
        // Subscribe to events after "ready"
        iframe.contentWindow?.postMessage(
          JSON.stringify({ method: "addEventListener", value: "play" }),
          "*"
        );
        iframe.contentWindow?.postMessage(
          JSON.stringify({ method: "addEventListener", value: "pause" }),
          "*"
        );
        iframe.contentWindow?.postMessage(
          JSON.stringify({ method: "addEventListener", value: "ended" }),
          "*"
        );
        
      } else if (data.event === "play") {
        gtm.push({ event: "vimeo_play", videoId: iframeId, vimeoTime: null});
      } else if (data.event === "pause") {
        gtm.push({ event: "vimeo_pause", videoId: iframeId, vimeoTime: data.data.seconds });
      } else if (data.event === "ended") {
        gtm.push({ event: "vimeo_end", videoId: iframeId, vimeoTime: data.data.seconds });
      }
    };

    window.addEventListener("message", handleVimeoMessage);

    return () => {
      window.removeEventListener("message", handleVimeoMessage);
    };
  }, [iframeId]);
};
