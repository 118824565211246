export const paths = {
  index: "/",
  stripeConnectRedirect: "/stripe-connect-redirect",
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  auth: {
    jwt: {
      // magic: "/auth/magic",
      // verify: "/auth/verify",
      entry: '/auth/entry',
      entryStart: '/auth/entry/start',
    },
  },

  dashboard: {
    index: "/",
    account: "/account",
    accountTab: (tab: string) => `/account?tab=${tab}`,
    chat: "/chat",
    leads: "/leads",
    learn: "/learn",
    transactions: "/transactions",
    tutorials: "/tutorials",
    scenarios: "/scenarios",
    toolkit:"/toolkit",
    referrals:"/referrals",
    upgrade:"/upgrade",
    marketing:"/marketing",
    quiz:"/quiz",
    clientRoles: {
      index: '/clients',
      create: '/clients/create',
      analysis: '/clients/analysis',
      print: "/clients/print",
      printCreate: "/clients/print/create",
    },
    learnPlans: "/learn/plans",
    learnAnalysis: "/learn/analysis",
    courseDetails: (courseId: string) => `/tutorials/${courseId}`, // New path added here
  },
  resetPassword: "/reset-password/:token",
  forgotPassword: "/forgot-password",
  notAuthorized: "/401",
  notFound: "/404",
  serverError: "/500",
};
