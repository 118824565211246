import { ProfessionalTransaction } from "src/types/snugtotal";

export interface ContextValues {
  error: string;
  initialLoading: boolean;
  savingNewValues: boolean;
  deletingEntity?: boolean;
}

export const CHARITIES = [
  {
    name: "Denver Gay Lesbian Flag Football League",
    ein: "455500720",
    image:
      "https:​/​/​cdn1.sportngin.com/​attachments/​logo_graphic/​3fe7-169690244/​DGLFFLCLogo_small.jpg",
  },
  {
    name: "The Center on Colfax",
    ein: "840738879",
    image:
      "https://lgbtqcolorado.org/dev2018/wp-content/uploads/2019/02/center_on_colfax_color_header_lrg.png",
  },
  {
    name: "One Colorado",
    ein: "271332464",
    image:
      "https://one-colorado.org/wp-content/uploads/2017/06/OneColorado_white-e1507132539372.png",
  },
  {
    name: "Dumb Friends League",
    ein: "840405254",
    image: "https://www.ddfl.org/images/logos/SVG/DFL-main-RGB.svg",
  },
  {
    name: "Two Blondes All Breed Rescue",
    ein: "472863183",
    image:
      "https://images.squarespace-cdn.com/content/v1/5a45c642d74cff15e27eb1c0/1515021282848-JIZ7DT6CWSIY82S5O3TT/2babr_small+logo.png?format=1500w",
  },

  {
    name: "Denver Children's Foundation",
    ein: "742535078",
    image:
      "https://www.denverchildrensfoundation.org/wp-content/uploads/2022/03/DCF_Color_Horizontal.png",
  },
  {
    name: "WeeCycle",
    ein: "823096264",
    image:
      "https://www.weecycle.org/wp-content/uploads/2016/01/weecyclelogo-2.png",
  },
  {
    name: "Denver Foundation",
    ein: "846048381",
    image:
      "https://denverfoundation.org/wp-content/uploads/2020/12/TDF_Logo_primary_color-v2.svg",
  },
  {
    name: "Boys and Girls Club of Metro Denver",
    ein: "840510404",
    image:
      "https://www.bgcmd.org/wp-content/uploads/2022/02/bgcmd-logo-website-1.png",
  },
  {
    name: "Code.org",
    ein: "460858543",
    image: "https://code.org/images/logo.svg",
  },
  {
    name: "There With Care",
    ein: "680606330",
    image:
      "https://therewithcare.org/wp-content/uploads/2017/01/TWC-logo-retina.png",
  },

  {
    name: "Conservation Colorado",
    ein: "300037131",
    image:
      "https://conservationco.org/wp-content/uploads/2019/01/main-logo-horz2.png",
  },
  {
    name: "Rocky Mountain Conservancy",
    ein: "840472090",
    image:
      "https://rmconservancy.org/wp-content/themes/gt-bootstrap/img/rmc-logo-white.png",
  },
];

export const demoTransactions = (professional_name: string | null): ProfessionalTransaction[] => {
  return [
    {
      id: "1",
      professional_user_data_ud_id: null,
      professional_contact_email: "youremail@yourbusiness.com",
      professional_full_name: professional_name || "Your Professional Name",
      professional_group_id: null,
      professional_group_payment_tier: null,
      transaction_type: "WILL",
      professional_pricing_option: "DEFAULT",
      client_user_data_ud_id: "2",
      client_contact_email: "aaron.burr.demo@getsnug.com",
      client_full_name: "Aaron Burr",
      estate_document_id: null,
      purchase_domain: null,
      amount: 49500,
      base_fee: 15000,
      description: "(DEMO Example) Will for Aaron Burr",
      source: "CLIENT_ADD",
      processing_fee: 0,
      net_payout_to_professional: 34500,
      referrer_user_data_ud_id: null,
      referrer_full_name: null,
      referrer_email: null,
      referree_email: null,
      referree_name: null,
      created_at: new Date("2024-01-01"),
    },
    {
      id: "2",
      professional_user_data_ud_id: null,
      professional_contact_email: "youremail@yourbusiness.com",
      professional_full_name: professional_name || "Your Professional Name",
      professional_group_id: null,
      professional_group_payment_tier: null,
      transaction_type: "TRUST",
      professional_pricing_option: "DEFAULT",
      client_user_data_ud_id: "3",
      client_contact_email: "alexander.hamilton.demo@getsnug.com",
      client_full_name: "Alexander Hamilton",
      estate_document_id: null,
      purchase_domain: null,
      amount: 129500,
      base_fee: 15000,
      description: "(DEMO Example) Trust for Alexander Hamilton",
      source: "ADD_CLIENT",
      processing_fee: 0,
      net_payout_to_professional: 114500,
      referrer_user_data_ud_id: null,
      referrer_full_name: null,
      referrer_email: null,
      referree_email: null,
      referree_name: null,
      created_at: new Date("2024-01-01"),
    },
    {
      id: "3",
      professional_user_data_ud_id: null,
      professional_contact_email: "youremail@yourbusiness.com",
      professional_full_name: professional_name || "Your Professional Name",
      professional_group_id: null,
      professional_group_payment_tier: null,
      transaction_type: "TRUST",
      professional_pricing_option: "DEFAULT",
      client_user_data_ud_id: "4",
      client_contact_email: "george.washington.demo@getsnug.com",
      client_full_name: "George Washington",
      estate_document_id: null,
      purchase_domain: null,
      amount: 129500,
      base_fee: 15000,
      description: "(DEMO Example) Trust for George Washington",
      source: "ADD_CLIENT",
      processing_fee: 0,
      net_payout_to_professional: 114500,
      referrer_user_data_ud_id: null,
      referrer_full_name: null,
      referrer_email: null,
      referree_email: null,
      referree_name: null,
      created_at: new Date("2024-01-01"),
    },
    {
      id: "4",
      professional_user_data_ud_id: null,
      professional_contact_email: "youremail@yourbusiness.com",
      professional_full_name: professional_name || "Your Professional Name",
      professional_group_id: null,
      professional_group_payment_tier: null,
      transaction_type: "TRUST",
      professional_pricing_option: "DEFAULT",
      client_user_data_ud_id: "5",
      client_contact_email: "thomas.jefferson.demo@getsnug.com",
      client_full_name: "Thomas Jefferson",
      estate_document_id: null,
      purchase_domain: null,
      amount: 129500,
      base_fee: 15000,
      description: "(DEMO Example) Trust for Thomas Jefferson",
      source: "ADD_CLIENT",
      processing_fee: 0,
      net_payout_to_professional: 114500,
      referrer_user_data_ud_id: null,
      referrer_full_name: null,
      referrer_email: null,
      referree_email: null,
      referree_name: null,
      created_at: new Date("2024-01-01"),
    },
    {
      id: "5",
      professional_user_data_ud_id: null,
      professional_contact_email: "youremail@yourbusiness.com",
      professional_full_name: professional_name || "Your Professional Name",
      professional_group_id: null,
      professional_group_payment_tier: null,
      transaction_type: "WILL",
      professional_pricing_option: "DEFAULT",
      client_user_data_ud_id: "6",
      client_contact_email: "angelica.schuyler.demo@getsnug.com",
      client_full_name: "Angelica Schuyler",
      estate_document_id: null,
      purchase_domain: null,
      amount: 49500,
      base_fee: 15000,
      description: "(DEMO Example) Will for Angelica Schuyler",
      source: "LANDING_PAGE",
      processing_fee: 0,
      net_payout_to_professional: 34500,
      referrer_user_data_ud_id: null,
      referrer_full_name: null,
      referrer_email: null,
      referree_email: null,
      referree_name: null,
      created_at: new Date("2024-01-01"),
    },
  ];
};
