import { useEffect, useState, type FC, type ReactNode } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { Theme } from "@mui/material/styles/createTheme";
import { styled } from "@mui/material/styles";

import type { NavColor } from "src/types/settings";

import type { Section } from "../config";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { FreeTrialBanner } from "./free-trial-banner";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import { useSections } from "../config";

import useUserDataProvider from "src/contexts/snug/userData-context";
import { PopupModal } from "react-calendly";
import { useDialog } from "src/hooks/use-dialog";
import { ProductDemoPlayer } from "src/sections/dashboard/overview/product-demo-player";
import { WelcomePlayer } from "src/sections/dashboard/overview/welcome-player";
import { PriceSheetDialog } from "src/sections/dashboard/overview/price-sheet-dialog";

const SIDE_NAV_WIDTH: number = 280;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, navColor } = props;
  const sections = useSections();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  const { isProTier, isCobranding, isWhiteLabel, professionalGroup, userData } =
    useUserDataProvider();

  const [demoIsOpen, setDemoIsOpen] = useState(false);
  const productDemoDialog = useDialog();
  const playerDialog = useDialog();
  const priceSheetDialog = useDialog();

  useEffect(() => {
    const showDemoPopup =
      professionalGroup?.show_demo_popup &&
      !professionalGroup?.professional_payment_tier;
    const demoPopupShown = sessionStorage.getItem("demoPopupShown");
    if (showDemoPopup && !demoPopupShown) {
      setDemoIsOpen(true);
      sessionStorage.setItem("demoPopupShown", "true");
    }
  }, [professionalGroup]);

  useEffect(() => {
    const showDemoDefaultPopup = !professionalGroup?.professional_payment_tier;
    const demoDefaultPopupShown = sessionStorage.getItem(
      "demoDefaultPopupShown"
    );
    if (showDemoDefaultPopup && !demoDefaultPopupShown) {
      playerDialog.handleOpen();
      sessionStorage.setItem("demoDefaultPopupShown", "true");
    }
  }, [professionalGroup, playerDialog]);

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          isPro={isProTier}
          isWhiteLabel={isWhiteLabel || isCobranding}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
          isPro={isProTier}
          isWhiteLabel={isWhiteLabel || isCobranding}
        />
      )}
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>
          {/* {!(isProTier || isCobranding || isWhiteLabel) && <FreeTrialBanner />} */}
          {(isCobranding || isWhiteLabel) &&
            !professionalGroup?.onboarding_completed_at && <FreeTrialBanner />}
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
      <PopupModal
        url={`https://calendly.com/d/cmhf-g7y-8r9?hide_gdpr_banner=1&utm_source=dashboard&utm_content=${userData?.professional_group_role_user_data?.id}`}
        onModalClose={() => setDemoIsOpen(false)}
        open={demoIsOpen}
        prefill={{
          email: userData?.contact_email,
          name: userData?.full_name,
        }}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")!}
      />
      <ProductDemoPlayer
        onClose={productDemoDialog.handleClose}
        open={productDemoDialog.open}
        openCalendly={setDemoIsOpen}
      />
      <PriceSheetDialog
        onClose={priceSheetDialog.handleClose}
        open={priceSheetDialog.open}
        openCalendly={setDemoIsOpen}
      />
      <WelcomePlayer
        onClose={playerDialog.handleClose}
        open={playerDialog.open}
        openCalendly={setDemoIsOpen}
        openPriceSheet={priceSheetDialog.handleOpen}
      />
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
