import { useEffect } from "react";

import { gtm } from "src/libs/gtm";

export const usePageView = (page_title: string): void => {
  useEffect(() => {
    document.title = page_title;
    gtm.push({ event: "page_view", page_title });
  }, [page_title]);
};
