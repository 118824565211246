import type { FC } from 'react';
import propTypes from 'prop-types';
import type { SxProps } from '@mui/system/styleFunctionSx';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

interface PricingPlanProps {
  step: string;
  stepName: string;
  description: string;
  features: string[];
  sx?: SxProps<Theme>;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const PricingPlan: FC<PricingPlanProps> = (props) => {
  const {
    step,
    stepName,
    description,
    features,
    sx,
    ...other
  } = props;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb:5,
        ...sx
      }}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">
          {step}
        </Typography>
        <Typography
          sx={{ mt: 2 }}
          variant="h4"
        >
          {stepName}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ mt: 2 }}
          variant="body1"
        >
          {description}
        </Typography>
      </Box>
      <Divider />
      {stepName === 'Onboarding' &&
      <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:1,
        }}
      >
        <HtmlTooltip title='Your clients will have a dedicated app to log in and create documents. This app can be white labeled with your logo and domain, or use a getsnug.com subdomain and Snug logo.'>
          <Stack direction='row' spacing={1}>
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Client Experience</Typography>
          
                     <HelpOutlineOutlinedIcon sx={{color:'#ff9c07', fontSize: '0.9rem'}} />       
          
          </Stack>
          </HtmlTooltip>
        <Typography variant='caption' sx={{mb:2}}><em>(choose one)</em></Typography>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                Co-branding: $500
              </Typography>
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                White label: $1,000
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <HtmlTooltip title='Your agents will have a dedicated dashboard to manage clients. This app can be white labeled with your logo and domain, or use dashboard.getsnug.com and the Snug logo.'>
          <Stack direction='row' spacing={1}>
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Agent Experience</Typography>
          
                     <HelpOutlineOutlinedIcon sx={{color:'#ff9c07', fontSize: '0.9rem'}} />       
          
          </Stack>
          </HtmlTooltip>
        <Typography variant='caption' sx={{mb:2}}><em>(choose one)</em></Typography>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                Co-branding: $0
              </Typography>
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                White label: $5,000
              </Typography>
        </Stack>
      </Box>
      </>
      }
      {stepName === 'Invitations' &&
      <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Agent Accounts</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                Unlimited
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Admin Accounts</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                Unlimited
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Client Accounts</Typography>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                Unlimited
              </Typography>
        </Stack>
      </Box>
      </>
      }
      {stepName === 'Purchases' &&
      <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <HtmlTooltip title='Will packages include a Last Will & Testament, Financial Power of Attorney, Health Care Directive, and free access to all Total Planning and sharing/collaboration tools.'>
          <Stack direction='row' spacing={1}>
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Will Package</Typography>
          
                     <HelpOutlineOutlinedIcon sx={{color:'#ff9c07', fontSize: '0.9rem'}} />       
          
          </Stack>
          </HtmlTooltip>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                $150
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <HtmlTooltip title='Trust packages include a Joint or Individual Revocable Living Trust, Pour Over Will, Financial Power of Attorney, Health Care Directive, Quitclaim Deed, and free access to all Total Planning and sharing/collaboration tools.'>
          <Stack direction='row' spacing={1}>
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Trust Package</Typography>
          
                     <HelpOutlineOutlinedIcon sx={{color:'#ff9c07', fontSize: '0.9rem'}} />       
          
          </Stack>
          </HtmlTooltip>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                $400
              </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          pb:0,
        }}
      >
        <HtmlTooltip title='Client purchases can be made directly within the Snug app, with a 5% payment processing fee on any net profit. Alternatively, purchases can be made on an existing payment platform for no additional fees.'>
          <Stack direction='row' spacing={1}>
        <Typography variant='h6' sx={{color:'#ff9c07', mb:1}}>Payment Processing</Typography>
          
                     <HelpOutlineOutlinedIcon sx={{color:'#ff9c07', fontSize: '0.9rem'}} />       
          
          </Stack>
          </HtmlTooltip>
        <Stack
          spacing={1}
        >
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                Collected by you: 0%
              </Typography>
              <Typography
                sx={{ fontWeight: 500 }}
                variant="subtitle1"
              >
                Collected by Snug: 5%
              </Typography>
        </Stack>
      </Box>
      </>
      }
    </Card>
  );
};

PricingPlan.propTypes = {
  step: propTypes.string.isRequired,
  stepName: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  features: propTypes.array.isRequired,
  sx: propTypes.object
};
