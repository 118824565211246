import type { FC, ReactNode } from "react";
import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import useWhiteLabelConfig from "src/contexts/snug/config-context";

const TOP_NAV_HEIGHT: number = 64;

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#1C2536",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  height: "100%",
}));

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { wl_professional_group } = useWhiteLabelConfig();
  const { children } = props;

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current && wl_professional_group?.dark_logo && wl_professional_group?.total_app_logo_multiplier) {
        const { naturalWidth, naturalHeight } = imageRef.current;
        const aspectRatio = naturalWidth / naturalHeight;
        let maxWidth = 180;
        let maxHeight = 30;

        // Adjust maxWidth for aspect ratios
        if (aspectRatio < 2.9) {
          maxHeight = 48;
        }
        if (aspectRatio >= 2.9 && aspectRatio <= 4) {
          maxHeight = 40;
        }
        if (aspectRatio > 4) {
          maxHeight = 35;
        }

        if (naturalWidth > maxWidth || naturalHeight > maxHeight) {
          if (maxWidth / aspectRatio < maxHeight) {
            setDimensions({ width: maxWidth * wl_professional_group?.total_app_logo_multiplier, height: maxWidth / aspectRatio * wl_professional_group?.total_app_logo_multiplier });
          } else {
            setDimensions({
              width: maxHeight * aspectRatio * wl_professional_group?.total_app_logo_multiplier,
              height: maxHeight * wl_professional_group?.total_app_logo_multiplier,
            });
          }
        } else {
          setDimensions({ width: naturalWidth * wl_professional_group?.total_app_logo_multiplier, height: naturalHeight * wl_professional_group?.total_app_logo_multiplier });
        }
      }
    };

    const img = imageRef.current;

    if (img && wl_professional_group?.dark_logo) {
      img.onload = handleResize;
      img.src = wl_professional_group?.dark_logo;
    }

    return () => {
      if (img) {
        img.onload = null;
      }
    };
  }, [wl_professional_group?.dark_logo, wl_professional_group?.total_app_logo_multiplier]);

  return (
    <LayoutRoot>
      <Box
        component="header"
        sx={{
          left: 0,
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Container maxWidth="lg">
          <Stack direction="row" spacing={2} sx={{ height: TOP_NAV_HEIGHT }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{ maxWidth: 180, maxHeight: 30, display: "inline-flex" }}
              >
                <img
                  ref={imageRef}
                  src={wl_professional_group?.dark_logo || undefined}
                  style={{ width: dimensions.width, height: dimensions.height }}
                />
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flex: "1 1 auto",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: "60px",
              md: "120px",
            },
          }}
        >
          {children}
        </Container>
      </Box>
    </LayoutRoot>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
