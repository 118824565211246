import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

const StripeConnectRedirect = () => {
  useEffect(() => {
    // get "email" from url query params
    const urlParams = new URLSearchParams(window.location.search);

    // get the email from the query params
    const email = urlParams.get("email");

    // redirect to the stripe connect page by calling backend API - https://api.getsnug.com/api/vs/connect-onboarding/ - response body looks like {"data": {"stripe_connect_onboarding_link": account_link.url}}. redirect to account_link.url
    fetch("https://api.getsnug.com/api/v3/connect-onboarding/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data.data.stripe_connect_onboarding_link;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <>
      <div>
        <Card elevation={16} sx={{ borderRadius: 1, p: 6 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              spacing={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <img
                src="https://s3.us-east-2.amazonaws.com/assets.getsnug.com/files/stripe_logo.png"
                style={{ maxWidth: "50px" }}
              />
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Redirecting to Stripe
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ mt: 1, textAlign: "center" }}
              >
                You will be automatically redirected to Stripe to connect your
                account. One moment while we create your secure connection.
              </Typography>
            </Stack>
          </Box>
        </Card>
      </div>
    </>
  );
};

export default StripeConnectRedirect;
