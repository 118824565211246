import type { FC, ReactNode } from "react";
import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";

import { Issuer } from "src/utils/auth";
import { emailVerify } from "src/utils/snug/snugAuthApi";
import { User } from "src/types/user";
import { UserDataObj, UtmData } from "src/types/snugtotal";
import axios from "axios";
import { GenericPath } from "src/utils/snug/estateAppApi";
import toast from "react-hot-toast";
import { useLocation } from "react-router";

const STORAGE_KEY = "s_at";
const REFRESH_STORAGE_KEY = "s_rt";

type VerifyEmailRequest = {
  token: string;
  password: string | null;
  first_name: string | null;
};

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  user: User | null;
}

enum ActionType {
  INITIALIZE = "INITIALIZE",
  EMAIL_VERIFY = "EMAIL_VERIFY",
  SIGN_IN = "SIGN_IN",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  SIGN_UP = "SIGN_UP",
  SIGN_OUT = "SIGN_OUT",
  REFRESH_USER = "REFRESH_USER",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    isEmailVerified: boolean;
    user: User | null;
    lastRefresh: number;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: User | null;
  };
};

type RefreshAction = {
  type: ActionType.REFRESH_TOKEN;
  payload: {
    user: User | null;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    payload: { user: User; userData: UserDataObj } | null;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type VerifyAction = {
  type: ActionType.EMAIL_VERIFY;
  payload: {
    user: User;
  };
};
type RefreshUser = {
  type: ActionType.REFRESH_USER;
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | SignInAction
  | RefreshAction
  | SignUpAction
  | SignOutAction
  | VerifyAction
  | RefreshUser;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isEmailVerified: false,
  user: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  REFRESH_TOKEN: (state: State, action: RefreshAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { payload } = action.payload;
    const user = payload?.user || null;
    return {
      ...state,
      isAuthenticated: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    isEmailVerified: false,
    user: null,
  }),
  EMAIL_VERIFY: (state: State, action: VerifyAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isEmailVerified: true,
      isAuthenticated: true,
      user,
    };
  },
  REFRESH_USER: (state: State, action: RefreshAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  signIn: (email: string, password: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  verifyEmail: (request: VerifyEmailRequest) => Promise<void>;
  signUp: (
    email: string,
    password: string,
    name: string,
    utm_data?: UtmData[] | null
  ) => Promise<{ user: User; userData: UserDataObj } | null>;
  signOut: () => Promise<void>;
  refreshUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
  signUp: () => Promise.resolve(null),
  signOut: () => Promise.resolve(),
  refreshUser: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const queryParams = new URLSearchParams(location.search);
      let accessToken = null;
      accessToken = queryParams.get("at");
      if (accessToken) {
        localStorage.setItem("s_at", accessToken);
        queryParams.delete("at");
      }

      const s_at = window.localStorage.getItem(STORAGE_KEY);
      if (s_at) {
        const user = await authApi.me({ s_at });
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            isEmailVerified: user?.email_verification?.email_verified ?? false,
            user,
            lastRefresh: Date.now(), // Update the lastRefreshed property
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            isEmailVerified: false,
            user: null,
            lastRefresh: Date.now(), // Update the lastRefreshed property
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          isEmailVerified: false,
          user: null,
          lastRefresh: Date.now(), // Update the lastRefreshed property
        },
      });
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      // clear tokens from local storage
      localStorage.removeItem("s_at");
      localStorage.removeItem("s_rt");

      // for marketing purposes we need to log a log in event and attach it to a paid vs non paid user. This is caught in the user data context and removed.
      sessionStorage.setItem("login", "true");

      const { s_at, s_rt } = await authApi.signIn({
        email: email.toLocaleLowerCase().trim(),
        password,
      });
      localStorage.setItem(STORAGE_KEY, s_at);
      localStorage.setItem(REFRESH_STORAGE_KEY, s_rt);
      const user = await authApi.me({ s_at });
      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const refreshToken = useCallback(async (): Promise<void> => {
    const s_rt = window.localStorage.getItem(REFRESH_STORAGE_KEY);
    try {
      if (!s_rt) {
        throw new Error("No access token found");
      }
      const { s_at: newAccessToken } = await authApi.refresh({
        refresh: s_rt,
      });
      localStorage.setItem(STORAGE_KEY, newAccessToken);
      localStorage.setItem(REFRESH_STORAGE_KEY, s_rt);
      const user = await authApi.me({ s_at: newAccessToken });
      dispatch({
        type: ActionType.REFRESH_TOKEN,
        payload: {
          user,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.SIGN_OUT,
      });
    }
  }, [dispatch]);

  const verifyEmail = useCallback(
    async (request: VerifyEmailRequest): Promise<void> => {
      // clear tokens from local storage
      localStorage.removeItem("s_at");
      localStorage.removeItem("s_rt");

      const { token, password, first_name } = request;
      const res = await emailVerify(token, password, first_name);
      const user = await authApi.me({ s_at: res.data.access });
      localStorage.setItem(STORAGE_KEY, res.data.access);
      dispatch({
        type: ActionType.EMAIL_VERIFY,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (
      email: string,
      password: string,
      name: string,
      utm_data?: UtmData[] | null
    ): Promise<{ user: User; userData: UserDataObj } | null> => {
      try {
        const handleUserDataOnSignup = async (
          email: string,
          name: string,
          accessToken: string
        ) => {
          // This is a kludge to get the user data object created on signup.
          const instance = axios.create({
            baseURL: process.env.REACT_APP_SNUG_API_URL,
          });
          try {
            const uD: UserDataObj = {
              ud_id: null, // This is null because we are creating a new user data
              full_name: name,
              contact_email: email.toLocaleLowerCase().trim(),
              utm_data: utm_data || [],
              assigned_total_domain: window.location.host.replace("https://", "dashboard.getsnug.com"),
            };
            return await instance.post(GenericPath.USER_DATA_V3, uD, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "X-User-Domain": window.location.host.replace("https://", "dashboard.getsnug.com"),
              },
            });
          } catch (error) {
            console.error("Sign up error.");
            throw error;
          }
        };

        // remove tokens from local storage
        localStorage.removeItem("s_at");
        localStorage.removeItem("s_rt");

        const { user } = await authApi.signUp({
          email: email.toLocaleLowerCase().trim(),
          name: name.trim(),
          password,
        });
        const { s_at } = await authApi.signIn({
          email: email.toLocaleLowerCase().trim(),
          password,
        });

        const userData = await handleUserDataOnSignup(email, name, s_at);
        localStorage.setItem(STORAGE_KEY, s_at);
        dispatch({
          type: ActionType.SIGN_UP,
          payload: {
            payload: { user: user, userData: userData?.data?.data },
          },
        });
        return { user: user, userData: userData?.data?.data };
      } catch (err) {
        // if error response code from axios api is 409 with response.data.code === '2', then set is authenticated to true
        if (err?.response?.status === 409 && err?.response?.data?.code === 2) {
          console.error(`User already exists, but not verified. ${err}`);
          // in this case user is already registered, so we set is authenticated to true
          // but the user does not have a verified email so we let them pass registration
          // and go to email verification.
          dispatch({
            type: ActionType.SIGN_UP,
            payload: {
              payload: null,
            },
          });
        } else {
          toast.error(
            "There was an error signing up. It seems like you are already registered. Please try logging in."
          );
          throw new Error(
            "You are already registered. Please log in. If you need further assistance reach out to our support channel."
          );
        }
        return null;
      }
    },
    [dispatch]
  );

  const refreshUser = useCallback(async (): Promise<void> => {
    const s_at = window.localStorage.getItem(STORAGE_KEY);

    if (!s_at) {
      throw new Error("No access token found");
    }

    const user = await authApi.me({ s_at });
    dispatch({
      type: ActionType.REFRESH_USER,
      payload: {
        user: user,
      },
    });
  }, [dispatch]);

  const signOut = useCallback(async (): Promise<void> => {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(REFRESH_STORAGE_KEY);
    await dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        refreshToken,
        verifyEmail,
        signUp,
        signOut,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
