import type { FC } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { paths } from "src/paths";
import { useGtmVimeo } from "src/hooks/use-gtm-vimeo";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

interface WelcomePlayerProps {
  onClose?: () => void;
  open?: boolean;
  openCalendly: React.Dispatch<React.SetStateAction<boolean>>;
  openPriceSheet?: () => void;
}

export const WelcomePlayer: FC<WelcomePlayerProps> = (props) => {
  const { onClose, open = false, openCalendly, openPriceSheet } = props;

  useGtmVimeo('vimeo-welcome-player'); // Custom hook to track Vimeo events

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            p: 2,
          }}
        >
          <Button
            color="inherit"
            startIcon={
              <SvgIcon>
                <ArrowLeftIcon />
              </SvgIcon>
            }
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mb: 2,
            mt: 0,
          }}
        >
          <Box
            sx={{
              mb: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ maxWidth: 600, width: "100%" }}>
              <div style={{ padding: "61.5% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/1018091397?badge=0&autopause=0&player_id=0&app_id=58479&enablejsapi=1"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  title="Snug - Pro Demo Account"
                  id="vimeo-welcome-player"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </Box>
            <Typography variant="h3" sx={{ textAlign: "center", mb: 1, mt: 4 }}>
              Welcome to Snug!
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ my: 1, textAlign: "center", px: 5, mb: 0 }}
              variant="body1"
            >
              See what Snug has to offer and how to get
              the most from your demo account.  Explore the full product demo and tutorials below to see the full product experience for you, your clients, and your team.
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
              <Button
                startIcon={<CalendarMonthOutlinedIcon />}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose?.(); // Safely calling onClose if it is defined
                  openCalendly(true);
                }}
              >
                Book a demo
              </Button>
              <Button
                variant="text"
                href={paths.dashboard.tutorials}
              >
                Tutorials
              </Button>
              <Button
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose?.(); // Safely calling onClose if it is defined
                  openPriceSheet?.();
                }}
              >
                Pricing
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

WelcomePlayer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
